<template>
  <v-lazy
    :width="!$store.state.isMobile ? '80%' : ''"
    class="card flex"
    :options="{
      threshold: 0.5,
    }"
    transition="fade-transition"
  >
    <div class="card__details flex">
      <img
        :style="!$store.state.isMobile ? 'height: 250px;' : ''"
        class="card-img"
        :src="item.photos[0]"
        alt="photo"
      />
      <div class="header__card">
        <div class="checkbox_block">
          <label class="checkbox__label gray-text">
            <input
              class="checkbox"
              type="checkbox"
              id="withFinishing"
              v-model="selected"
            />
            <span class="checkmark"></span>
          </label>
        </div>
      </div>
      <div class="description flex">
        <div class="description__block">
          <p class="title">
            {{
              item.lot_name.length > 22
                ? item.lot_name.substr(0, 23)
                : item.lot_name
            }}
          </p>
          <p class="price">
            <b class="full-price"
              ><span class="full-price">{{ item.currency }}</span
              >{{ item.fullPrice }}</b
            >
          </p>
          <p class="address">
            {{
              item.lot_adress.length > 38
                ? `${item.lot_adress.substr(0, 30)}...`
                : item.lot_adress
            }}
          </p>
        </div>
        <div class="id-block flex">
          <div class="item__type">{{ item.status }}</div>
          <!-- <b class="ident">ID: {{ item.id }}</b>-->
        </div>
      </div>
    </div>
  </v-lazy>
</template>

<script>
export default {
  name: "Card-Component",
  props: {
    item: Object,
    modelValue: Boolean,
  },
  emits: ["update:modelValue"],
  data() {
    return {
      selected: false,
    };
  },
  watch: {
    item: {
      deep: true,
      handler(val) {
        if (!val.selected) {
          this.selected = false;
        }
        console.log({ val });
      },
    },
    selected(val) {
      this.$emit("update:modelValue", val);
      if (this.selected) {
        const index = this.$store.state.selectItems.findIndex(
          (e) => e.id == this.item.id
        );
        if (index == -1) {
          this.$store.commit("addSelectItems", this.item);
        }
      } else {
        this.$store.commit("deleteSelectItems", this.item);
      }
    },
  },
  created() {
    this.selected = this.item.selected;
  },
};
</script>

<style scoped>
.gray-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 180%;
  color: #878787;
}
.card {
  position: relative;
  border: 1px solid #e5e5e5;
  border-radius: 20px;
}
.card__block {
  flex-direction: row;
}
.card__details {
  width: 100%;
}
.card-img {
  max-width: 35%;
  border-radius: 20px;
  object-fit: cover;
}
.description {
  flex-direction: column;
  padding: 12px;
}
.characters-block {
  flex-wrap: wrap;
  margin-block: 10px;
}
.id-block {
  justify-content: space-between;
}
.title__block {
  justify-content: space-between;
  margin-bottom: 10px;
}
.title {
  font-size: 16px;
}
.price {
  font-size: 18px;
  color: #ff8351;
}
.address {
  font-size: 12px;
  font-weight: 400;
}
.characters {
  display: flex;
  align-items: center;
  margin-right: 5px;
}
.characters__text {
  margin-right: 3px;
}
.characters__icon {
  margin-right: 3px;
}
.status-block {
  flex-direction: row;
}
.item__type {
  padding: 0 4px;
  border: 1px solid #ff8351;
  border-radius: 6px;
  background-color: #ff8351;
  color: #fff;
}
.checkbox_block {
  position: absolute;
  top: 15px;
  right: 0;
}
.checkbox_label {
  padding: 0;
  margin: 0;
}
</style>
