<template>
  <v-lazy
    class="card"
    :options="{
      threshold: 0.5,
    }"
    :height="$store.state.isMobile ? 550 : 450"
    :width="isDesktopCard ? 300 : ''"
    transition="fade-transition"
  >
    <div style="height: 100%">
      <img
        :class="$store.state.isMobile ? 'card-img' : 'card-img__desctop'"
        :src="item.url_server"
        alt="photo"
      />
      <div
        :class="
          !$store.state.isMobile
            ? 'description__desctop flex justify-space-between pb-5'
            : 'description flex justify-space-between pb-5'
        "
      >
        <div class="description__block">
          <div class="title__block flex">
            <h4 class="title ma-0 pa-0">{{ item.jk_name }}</h4>
          </div>

          <v-list-item lines="two" class="ma-0 pa-0">
            <v-list-item-subtitle class="address mb-4 ma-0 pa-0"
              >{{ item.jk_adress }}
              <v-tooltip
                :text="item.jk_adress"
                location="bottom"
                activator="parent"
              >
              </v-tooltip
            ></v-list-item-subtitle>
          </v-list-item>
          <p class="price">
            <b class="full-price"
              ><span class="full-price"
                >от {{ item.currency.trim() + item.priceFrom }}</span
              ></b
            >
            <span class="full-price">
              до {{ item.currency.trim() + item.priceTo }}</span
            >
          </p>
          <p class="price">
            <b class="full-price"
              ><span class="full-price">от </span>{{ item.squareFrom }} м²</b
            >
            <span class="full-price"> до </span>{{ item.squareTo }} м²
          </p>
        </div>
        <div class="characters-block flex justify-center mt-4">
          <div
            :class="`${
              isMap ? 'characters-block__items__map' : ''
            } characters-block__items justify-center text-center pa-3`"
          >
            {{ item.developer_lot }} объектов
          </div>
        </div>
        <!--<div class="id-block flex">
          <b class="ident">ID: {{ item.id }}</b>
        </div>-->
      </div>
    </div>
  </v-lazy>
</template>

<script>
export default {
  name: "Card-Component",
  props: {
    item: Object,
    modelValue: Boolean,
    isMap: Boolean,
    isDesktopCard: Boolean,
  },
  emits: ["update:modelValue"],
  data() {
    return {
      selected: false,
    };
  },
  watch: {
    selected(val) {
      this.$emit("update:modelValue", val);
    },
  },
};
</script>

<style scoped>
.card {
  position: relative;
  border: 1px solid #e5e5e5;
  border-radius: 20px;
}
.card__block {
  flex-direction: column;
}
.card-img {
  width: 100%;
  height: 50%;
  border-radius: 20px;
  object-fit: cover;
}
.card-img__desctop {
  width: 100%;
  height: 40%;
  border-radius: 20px;
  object-fit: cover;
}

.description__desctop {
  flex-direction: column;
  padding: 12px;
  height: 60%;
}

.description {
  flex-direction: column;
  padding: 12px;
  height: 50%;
}
.description__basic {
  height: 90%;
}
.characters-block__items {
  width: 70%;
  border: 1px solid #f4f6f9;
  border-radius: 35px;
  background-color: #f4f6f9;
}
.characters-block__items__map {
  margin-bottom: 40px;
}
.id-block {
  justify-content: end;
}
.title__block {
  justify-content: space-between;
  margin-bottom: 10px;
}
.price {
  font-size: 16px;
}
.address {
  font-size: 14px;
  font-weight: 400;
}
.characters {
  display: flex;
  align-items: center;
  margin-right: 5px;
}
.characters__text {
  margin-right: 3px;
}
.characters__icon {
  margin-right: 3px;
}
.status-block {
  flex-direction: row;
}
.item__type {
  position: absolute;
  padding: 0 4px;
  border: 1px solid #fff;
  border-radius: 6px;
  background-color: #fff;
  top: 18px;
  left: 15px;
}
.checkbox_block {
  position: absolute;
  top: 15px;
  right: 0;
}
.checkbox_label {
  padding: 0;
  margin: 0;
}
</style>
