<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    valueNav: "home",
  }),
  mounted() {
    if (window.innerWidth < 600) this.$store.commit("setMobileStatus", true);
    else this.$store.commit("setMobileStatus", false);
  },
};
</script>
