<template>
  <div class="sort__row flex align-center justify-space-between">
    <div class="sort__items">
      <v-list class="rounded-t-xl flex">
        <v-list-item
          :style="
            sortItem.name == modelValue.name ? 'color: #FF8351' : 'color: #000'
          "
          v-for="sortItem in sortTypes"
          :key="sortItem.id"
          :title="sortItem.name"
          @click="sortItems(sortItem)"
        >
        </v-list-item>
      </v-list>
    </div>
    <div class="type__view">
      <v-btn
        class="text-none"
        style="border-radius: 12px"
        prepend-icon="mdi-format-list-text"
        variant="outlined"
        @click="сhangeList()"
      >
        {{ typeItems }}
      </v-btn>
    </div>
  </div>
</template>
<script>
export default {
  name: "SortMenu",
  props: {
    modelValue: Object,
    typeItems: String,
    isList: Boolean,
  },
  emits: [
    "update:modelValue",
    "update:typeItems",
    "update:isList",
    "sortItems",
    "сhangeList",
  ],
  data() {
    return {
      sortTypes: [
        { id: 1, name: "Cначала новые" },
        { id: 2, name: "Cначала старые" },
        { id: 3, name: "Cначала дешевые" },
        { id: 4, name: "Cначала дорогие" },
      ],
      sortMenu: false,
    };
  },
  methods: {
    sortItems(sortItem) {
      this.$emit("sortItems", sortItem);
    },
    сhangeList() {
      this.$emit("сhangeList");
    },
  },
};
</script>
