<template>
  <v-sheet>
    <div class="container mt-4">
      <div class="header flex mb-3" v-if="$store.state.isMobile">
        <div class="header__title flex">
          <v-row class="justify-space-between">
            <v-col cols="1">
              <button
                class="btn-reset close__filter"
                @click="this.$emit('update:modelValue', false)"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M17 10C17 10.1989 16.921 10.3897 16.7803 10.5303C16.6397 10.671 16.4489 10.75 16.25 10.75L5.61201 10.75L9.77001 14.71C9.9064 14.8494 9.98312 15.0365 9.9839 15.2315C9.98467 15.4266 9.90943 15.6142 9.77414 15.7547C9.63885 15.8952 9.45416 15.9775 9.25923 15.9841C9.0643 15.9907 8.87447 15.921 8.73001 15.79L3.23001 10.54C3.15742 10.47 3.09968 10.3862 3.06025 10.2934C3.02081 10.2006 3.00049 10.1008 3.00049 10C3.00049 9.89919 3.02081 9.79941 3.06025 9.70663C3.09968 9.61385 3.15742 9.52997 3.23001 9.46001L8.73001 4.21001C8.8002 4.13826 8.88408 4.08133 8.97668 4.04259C9.06928 4.00386 9.16871 3.9841 9.26908 3.9845C9.36945 3.9849 9.46873 4.00544 9.56101 4.04491C9.6533 4.08437 9.73673 4.14196 9.80635 4.21426C9.87598 4.28657 9.93038 4.37211 9.96634 4.46582C10.0023 4.55953 10.0191 4.65951 10.0157 4.75982C10.0123 4.86014 9.98881 4.95875 9.9466 5.04983C9.9044 5.1409 9.84435 5.22257 9.77001 5.29001L5.61201 9.25001L16.25 9.25001C16.4489 9.25001 16.6397 9.32902 16.7803 9.46968C16.921 9.61033 17 9.80109 17 10Z"
                    fill="black"
                  />
                </svg>
              </button>
            </v-col>
            <v-col cols="8">
              <div class="title">
                Объявлений {{ this.$store.state.objs.length }}
              </div>
            </v-col>
          </v-row>
        </div>
        <div class="search__block flex">
          <div class="search">
            <v-text-field
              clearable
              v-model="search"
              label="Поиск"
              variant="outlined"
              hide-details
              @input="searchItems"
              @click:clear="this.filterItems = this.$store.state.objs"
            ></v-text-field>
          </div>
          <div class="filter__btn">
            <v-dialog
              v-model="filterMenu"
              transition="dialog-right-transition"
              scrollable
              fullscreen
              persistent
            >
              <template v-slot:activator="{ props: activatorProps }">
                <v-btn
                  height="100%"
                  width="100%"
                  class="me-2 text-none"
                  style="border-radius: 12px"
                  color="#FF8351"
                  prepend-icon="mdi-filter-variant"
                  variant="flat"
                  v-bind="activatorProps"
                >
                  Фильтр
                </v-btn>
              </template>
              <FilterWiev
                v-model="filterMenu"
                @updateFilterItemsMap="onUpdateFilterItemsMap"
                :isMap="true"
              ></FilterWiev>
            </v-dialog>
          </div>
        </div>
      </div>
      <yandex-map
        cursor-grab
        :settings="{
          location: {
            center: [37.617644, 55.755819],
            zoom: 9,
          },
        }"
        width="100%"
        height="85vh"
      >
        <yandex-map-default-scheme-layer />
        <yandex-map-default-features-layer />
        <yandex-map-controls :settings="{ position: 'top left' }">
        </yandex-map-controls>
        <yandex-map-controls
          :settings="{ position: 'bottom left', orientation: 'vertical' }"
        >
        </yandex-map-controls>
        <yandex-map-controls :settings="{ position: 'right' }">
          <yandex-map-zoom-control />
        </yandex-map-controls>
        <yandex-map-clusterer zoom-on-cluster-click>
          <YandexMapMarker
            v-for="item in filterItems"
            :key="item.id"
            :settings="{
              position: 'top left-center',
              coordinates: item.coordinates,
            }"
          >
            <div class="marker__container">
              <div class="clip" @click="item.cardDialog = !item.cardDialog">
                от {{ item.priceFrom }}
              </div>
              <div class="marker" />
              <v-bottom-sheet v-model="item.cardDialog">
                <v-card class="clip__card rounded-t-xl">
                  <Card
                    :item="item"
                    @click="item.dialog = !item.dialog"
                    :isMap="true"
                  ></Card>
                </v-card>
              </v-bottom-sheet>
            </div>
          </YandexMapMarker>
          <template #cluster="{ length }">
            <div class="cluster__marker fade-in">
              {{ length }}
            </div>
          </template>
        </yandex-map-clusterer>
      </yandex-map>
    </div>
  </v-sheet>
</template>
<script>
import {
  YandexMap,
  YandexMapDefaultSchemeLayer,
  YandexMapDefaultFeaturesLayer,
  YandexMapZoomControl,
  YandexMapControls,
  YandexMapMarker,
  YandexMapClusterer,
} from "vue-yandex-maps";
import FilterWiev from "../home/FilterWiev.vue";
import Card from "../home/Card.vue";

export default {
  name: "Card-Map",
  components: {
    YandexMap,
    YandexMapDefaultSchemeLayer,
    YandexMapDefaultFeaturesLayer,
    YandexMapZoomControl,
    YandexMapControls,
    YandexMapMarker,
    YandexMapClusterer,
    Card,
    FilterWiev,
  },
  props: {
    items: Array,
    modelValue: Boolean,
  },
  emits: ["update:modelValue"],
  data() {
    return {
      filterItems: [],
      filterMenu: false,
      dialog: false,
      search: "",
    };
  },
  methods: {
    swipe() {
      this.$emit("update:modelValue", false);
    },
    onUpdateFilterItemsMap() {
      this.filterItems = this.$store.state.objs;
    },
    searchItems() {
      console.log(this.search);
      if (this.search) {
        if (this.search.length > 0) {
          console.log(this.search, "search");
          this.filterItems = this.$store.state.objs.filter((item) => {
            return (
              //название, адрес, сумма,
              item.title.toLowerCase().indexOf(this.search.toLowerCase()) >
                -1 ||
              item.priceTo.toLowerCase().indexOf(this.search.toLowerCase()) >
                -1 ||
              item.priceFrom.toLowerCase().indexOf(this.search.toLowerCase()) >
                -1 ||
              item.address.toLowerCase().indexOf(this.search.toLowerCase()) > -1
            );
          });
          console.log(this.filterItems);
        } else {
          this.filterItems = this.$store.state.objs;
        }
      } else {
        this.filterItems = this.$store.state.objs;
      }
    },
  },
  mounted() {
    this.filterItems = this.$store.state.objs;
  },
};
</script>

<style scoped>
.clip {
  cursor: pointer;
  bottom: 22px;
  right: -73px;
  padding: 2px;
  position: fixed !important;
  background-color: #ff8152;
  border-radius: 13px;
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 14px;
  position: relative;
  text-align: center;
  min-width: 110px;
}

.clip::after {
  content: url("data:image/svg+xml;charset=UTF-8,%3csvg width='60' height='12' viewBox='0 0 60 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M59.3047 0H0.74414C1.58674 0.199128 2.46227 0.314337 3.36157 0.336229L17.7819 0.687281C23.6216 0.829443 28.6091 4.94152 29.8619 10.647C29.9967 11.2607 30.8624 11.2863 31.0331 10.6816L31.0768 10.5267C32.688 4.81974 37.8274 0.826804 43.7555 0.676281L56.6451 0.348997C57.5595 0.32578 58.4493 0.206156 59.3047 0Z' fill='%23FF8351'/%3e%3c/svg%3e ");
  position: absolute;
  top: 19px;
  display: grid;
  justify-items: center;
  width: 100%;
}

.clip span {
  color: white;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 23px;
  padding: 0 10px;
  width: 100%;
}
.cluster__marker {
  display: flex;
  align-items: center;
  justify-content: center;

  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 40px;
  border: 2px white solid;
  width: 25px;
  height: 25px;
  color: #fff;
  background-color: #ff8152;
}
.marker {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 40px;
  border: 2px white solid;
  width: 12px;
  height: 12px;
  background-color: #ff8152;
}

.marker span {
  font-size: 10px;
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  width: 21px;
  height: 21px;
  display: grid;
  justify-items: center;
  align-items: center;
}
</style>
