<template>
  <div class="filter__row">
    <v-sheet border rounded="xl" class="pa-4">
      <div class="search__row flex mb-4">
        <div class="type flex"></div>
        <div class="search mr-0">
          <v-text-field
            clearable
            v-model="search"
            label="Поиск"
            color="#FF8351"
            variant="outlined"
            hide-details
            @input="searchItems"
            @click:clear="clearSearch"
          ></v-text-field>
        </div>
      </div>
      <div class="filter__row flex">
        <div class="filter__btn mr-2">
          <v-dialog
            z-index="10"
            v-model="filterMenu"
            transition="dialog-right-transition"
            scrollable
            :fullscreen="$store.state.isMobile"
            :width="!$store.state.isMobile ? '60%' : ''"
            :height="!$store.state.isMobile ? '80%' : ''"
            :persistent="$store.state.isMobile"
          >
            <template v-slot:activator="{ props: activatorProps }">
              <v-btn
                height="100%"
                width="100%"
                class="me-2 text-none"
                style="border-radius: 12px; padding: 18px"
                prepend-icon="mdi-filter-variant"
                variant="outlined"
                v-bind="activatorProps"
              >
                Фильтр
              </v-btn>
            </template>
            <FilterWiev
              v-model="filterMenu"
              @updateFilterItems="onUpdateFilterItems"
            ></FilterWiev>
          </v-dialog>
        </div>
        <div class="rooms_block flex pl-4 mr-2">
          <p class="mr-2" style="color: #a1a7b0">Комнаты</p>
          <v-btn
            elevation="0"
            icon="mdi-numeric-1"
            class="rounded-circle mr-1"
            :disabled="modelValue"
            @click="
              this.$store.state.filterOptions.rooms.one =
                !this.$store.state.filterOptions.rooms.one
            "
            :color="
              this.$store.state.filterOptions.rooms.one ? '#FF8351' : '#fff'
            "
            >1</v-btn
          >
          <v-btn
            elevation="0"
            icon="mdi-numeric-2"
            class="rounded-circle mr-1"
            :disabled="modelValue"
            @click="
              this.$store.state.filterOptions.rooms.two =
                !this.$store.state.filterOptions.rooms.two
            "
            :color="
              this.$store.state.filterOptions.rooms.two ? '#FF8351' : '#fff'
            "
            >2</v-btn
          >
          <v-btn
            elevation="0"
            icon="mdi-numeric-3"
            class="rounded-circle mr-1"
            :disabled="modelValue"
            @click="
              this.$store.state.filterOptions.rooms.three =
                !this.$store.state.filterOptions.rooms.three
            "
            :color="
              this.$store.state.filterOptions.rooms.three ? '#FF8351' : '#fff'
            "
            >3</v-btn
          >
          <v-btn
            elevation="0"
            icon="mdi-numeric-4"
            class="rounded-circle mr-1"
            :disabled="modelValue"
            @click="
              this.$store.state.filterOptions.rooms.four =
                !this.$store.state.filterOptions.rooms.four
            "
            :color="
              this.$store.state.filterOptions.rooms.four ? '#FF8351' : '#fff'
            "
            >4</v-btn
          >
          <v-btn
            elevation="0"
            icon="mdi-numeric-5"
            class="rounded-circle mr-1"
            :disabled="modelValue"
            @click="
              this.$store.state.filterOptions.rooms.five =
                !this.$store.state.filterOptions.rooms.five
            "
            :color="
              this.$store.state.filterOptions.rooms.five ? '#FF8351' : '#fff'
            "
            >5</v-btn
          >
          <v-btn
            elevation="0"
            icon="mdi-numeric-6"
            class="rounded-circle mr-1"
            :disabled="modelValue"
            @click="
              this.$store.state.filterOptions.rooms.six =
                !this.$store.state.filterOptions.rooms.six
            "
            :color="
              this.$store.state.filterOptions.rooms.six ? '#FF8351' : '#fff'
            "
            >6+</v-btn
          >
        </div>
        <v-autocomplete
          class="mr-2"
          :items="this.$store.state.districts"
          v-model="this.$store.state.filterOptions.district"
          :disabled="modelValue"
          clearable
          chips
          color="#FF8351"
          multiple
          item-title="name"
          item-value="id"
          label="Район(ы)"
          variant="outlined"
          prepend-inner-icon="mdi-map-marker-outline"
          hide-details
        ></v-autocomplete>
        <div class="filter__price mr-2">
          <v-text-field
            v-model="this.$store.state.filterOptions.price"
            :disabled="modelValue"
            variant="outlined"
            label="Цена"
            min-width="250"
            hide-details
            @click="priceModal = !priceModal"
            color="#FF8351"
          ></v-text-field>
          <v-dialog v-model="priceModal" width="30%" class="price__modal">
            <v-card class="price__modal__card pa-6 rounded-xl">
              <div class="price__modal__header flex mb-5">
                <h2 class="mr-2" style="align-self: center">Цена</h2>
                <div class="flex">
                  <v-btn
                    class="rounded-circle mr-3"
                    elevation="0"
                    icon="mdi-numeric-1"
                    @click="this.$store.state.filterOptions.currency = '₽'"
                    :color="
                      this.$store.state.filterOptions.currency == '₽'
                        ? '#FF8351'
                        : '#F4F6F9'
                    "
                    >₽</v-btn
                  >
                  <v-btn
                    class="rounded-circle mr-3"
                    elevation="0"
                    icon="mdi-numeric-1"
                    @click="this.$store.state.filterOptions.currency = '$'"
                    :color="
                      this.$store.state.filterOptions.currency == '$'
                        ? '#FF8351'
                        : '#F4F6F9'
                    "
                    >$</v-btn
                  >
                  <v-btn
                    class="rounded-circle mr-3"
                    elevation="0"
                    icon="mdi-numeric-1"
                    @click="this.$store.state.filterOptions.currency = '€'"
                    :color="
                      this.$store.state.filterOptions.currency == '€'
                        ? '#FF8351'
                        : '#F4F6F9'
                    "
                    >€</v-btn
                  >
                </div>
              </div>
              <div class="price__modal__type mb-5 rounded-xl pa-2 flex">
                <v-btn
                  width="49%"
                  :color="
                    this.$store.state.filterOptions.priceType
                      ? '#474849'
                      : '#f4f6f9'
                  "
                  class="rounded-xl"
                  elevation="0"
                  @click="this.$store.state.filterOptions.priceType = true"
                  >За все</v-btn
                >
                <v-btn
                  width="49%"
                  class="rounded-xl"
                  elevation="0"
                  :color="
                    !this.$store.state.filterOptions.priceType
                      ? '#474849'
                      : '#f4f6f9'
                  "
                  @click="this.$store.state.filterOptions.priceType = false"
                  >За метр</v-btn
                >
              </div>
              <div class="price__modal__form mb-5">
                <v-text-field
                  class="mb-3"
                  v-model="this.$store.state.filterOptions.priceFrom"
                  clearable
                  color="#FF8351"
                  label="от"
                  variant="outlined"
                  hide-details
                ></v-text-field>
                <v-text-field
                  v-model="this.$store.state.filterOptions.priceTo"
                  clearable
                  color="#FF8351"
                  label="до"
                  variant="outlined"
                  hide-details
                ></v-text-field>
              </div>
              <div class="price__modal__actions flex justify-end">
                <v-btn variant="text" @click="priceModal = false">Отмена</v-btn>
                <v-btn variant="text" color="#FF8351" @click="getPriceFilter"
                  >Ок</v-btn
                >
              </div>
            </v-card>
          </v-dialog>
        </div>
        <v-btn
          color="#ff8351"
          elevation="0"
          min-height="54px"
          style="border-radius: 12px"
          :disabled="modelValue"
          :loading="modelValue"
          @click="getObjs"
          >Показать</v-btn
        >
        <v-btn color="#FF8351" variant="text" @click="reset"> Сбросить </v-btn>
      </div>
    </v-sheet>
  </div>
</template>
<script>
import FilterWiev from "../home/FilterWiev.vue";

export default {
  name: "SlowFilter",
  components: {
    FilterWiev,
  },
  props: {
    modelValue: Boolean,
  },
  emits: [
    "update:modelValue",
    "getObjs",
    "onUpdateFilterItems",
    "getPriceFilter",
    "searchItems",
    "clearSearch",
    "updateFilterItems",
  ],
  data() {
    return {
      search: "",
      filterMenu: false,
      priceModal: false,
    };
  },
  methods: {
    reset() {
      const filterOptions = {
        priceType: true,
        isHouse: false,
        isPlot: false,
        isApart: false,
        isSecondary: false,
        isNewBuild: false,
        isTerrace: false,
        isTownHouse: false,
        isWhitebox: false,
        isNotOtc: false,
        isOtc: false,
        rooms: {
          one: false,
          two: false,
          three: false,
          four: false,
          five: false,
          six: false,
        },
        price: "",
        priceTo: "",
        priceFrom: "",
        square: "",
        statusObj: [],
        selectStatus: "",
        currency: "₽",
        city: [],
        district: [],
        streets: [],
        metro: [],
      };
      this.$store.commit("filterOptionUpdate", filterOptions);
    },
    onUpdateFilterItems(objs) {
      this.$emit("onUpdateFilterItems", objs);
    },
    getPriceFilter() {
      this.priceModal = false;
      this.$emit("getPriceFilter");
    },
    searchItems() {
      this.$emit("searchItems", this.search);
    },
    clearSearch() {
      this.$emit("clearSearch");
    },
    getObjs() {
      this.$emit("getObjs");
    },
  },
};
</script>
<style scoped>
.search {
  width: 40%;
}
</style>
